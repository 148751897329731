import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { darken } from 'polished';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { useIsDarkMode } from '../../state/user/hooks';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { ApplicationModal } from '../../state/application/actions';
import { useModalOpen, useToggleModal } from '../../state/application/hooks';

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`;

export const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg1};
  transition: 0.2s;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
`;

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.bg1};
  transition: 0.2s;
`;

const MenuFlyout = styled.div`
  min-width: 8.125rem;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
  padding: 0.6rem 0.9rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;
  transition: 0.2s;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 3rem 10%;
    font-size: 1.6rem;
    border-radius: 0;
    background-color: ${({ theme }) => (useIsDarkMode() ? theme.bg1 : theme.primary3)};
    color: ${({ theme }) => (useIsDarkMode() ? theme.text1 : theme.white1)};
  `};
`;

const Content = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  `};
`;

const MenuBlock = styled.div`
  padding: 0.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.8rem 0;
  `};
`;

const SpecialItem = styled.span`
  display: inline-block;
  width: 100%;
  padding: 0.2rem 0;
  font-size: 1.1em;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.primary3};
  transition: 0.2s;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1.2em;
    color: ${({ theme }) => (useIsDarkMode() ? theme.primary3 : theme.white1)};
  `};
`;

const MenuItem = styled.a<{ alone: boolean | undefined; titleLink: boolean | undefined }>`
  cursor: pointer;
  flex: 1;
  padding: 0 0 0.2rem;
  padding-left: ${({ titleLink }) => (titleLink ? '' : '0.3rem')};
  color: ${({ theme }) => theme.text2};
  word-break: keep-all;
  white-space: nowrap;
  font-size: 0.9em;
  transition: 0.2s;
  text-decoration: none;

  ${({ theme, alone, titleLink }) =>
    titleLink
      ? `
      margin: ${alone ? '0' : '0 0 0.4rem'};
      font-weight: 700;
      font-size: 1em;
      color: ${theme.text1};
      transition: 0.2s;
  `
      : ''}

  ${({ theme, titleLink }) => theme.mediaWidth.upToSmall`
    padding: 0 0 0.3rem;
    padding-left: ${titleLink ? '' : '0.5rem'};
    color: ${({ theme }) => (useIsDarkMode() ? theme.text1 : theme.secondary2)};

    :hover,
    :focus {
      color: ${({ theme }) => (useIsDarkMode() ? darken(0.1, theme.text1) : darken(0.1, theme.secondary2))};
    }

    ${
      titleLink
        ? `
        font-size: 1.3em;
        color: ${useIsDarkMode() ? theme.text1 : theme.white1};

        :hover,
        :focus {
          color: ${useIsDarkMode() ? darken(0.1, theme.text1) : darken(0.1, theme.white1)};
        }
      `
        : ''
    }
  `};
`;

const CloseButton = styled.button`
  cursor: pointer;
  display: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: 1.4rem;
  border: none;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.text2};
  transition: 0.2s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.3rem;
    height: 70%;
    border-radius: 0.2rem;
    background-color: ${({ theme }) => theme.bg2};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover,
  &:focus {
    opacity: 0.6;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: initial;
    position: fixed;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => (useIsDarkMode() ? theme.text2 : theme.white1)};

    &::before,
    &::after {
      background-color: ${({ theme }) => (useIsDarkMode() ? theme.bg2 : theme.primary3)};
    }
  `};
`;

export default function Menu() {
  const node = useRef<HTMLDivElement>();
  const open = useModalOpen(ApplicationModal.MENU);
  const toggle = useToggleModal(ApplicationModal.MENU);
  const { t } = useTranslation();
  useOnClickOutside(node, open ? toggle : undefined);

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <Content>
            <MenuBlock>
              <MenuItem alone={false} titleLink={false} href="https://earn.min.i.ng" target="_blank">
                <SpecialItem>{t('earn')}</SpecialItem>
              </MenuItem>
              <MenuItem alone={false} titleLink={false} href="https://min.i.ng/token" target="_blank">
                <SpecialItem>{t('token')}</SpecialItem>
              </MenuItem>
            </MenuBlock>

            <MenuBlock>
              <MenuItem alone={false} titleLink href="https://min.i.ng/business" target="_blank">
                {t('business')}
              </MenuItem>

              <MenuItem alone={false} titleLink={false} href="https://min.i.ng/business#card" target="_blank">
                {t('businessCard')}
              </MenuItem>
              <MenuItem alone={false} titleLink={false} href="https://min.i.ng/business#company" target="_blank">
                {t('company')}
              </MenuItem>
            </MenuBlock>

            <MenuBlock>
              <MenuItem alone={false} titleLink href="https://min.i.ng/taxi" target="_blank">
                {t('taxi')}
              </MenuItem>

              <MenuItem alone={false} titleLink={false} href="https://min.i.ng/taxi#rider" target="_blank">
                {t('rider')}
              </MenuItem>
              <MenuItem alone={false} titleLink={false} href="https://min.i.ng/taxi#driver" target="_blank">
                {t('driver')}
              </MenuItem>
            </MenuBlock>

            <MenuBlock>
              <MenuItem alone titleLink href="https://min.i.ng/coffee" target="_blank">
                {t('coffee')}
              </MenuItem>
            </MenuBlock>

            <MenuBlock>
              <MenuItem alone titleLink href="https://min.i.ng/delivery" target="_blank">
                {t('delivery')}
              </MenuItem>
            </MenuBlock>
          </Content>

          <CloseButton onClick={toggle} />
        </MenuFlyout>
      )}
    </StyledMenu>
  );
}
