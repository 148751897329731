import React from 'react';
import styled from 'styled-components';
import { BRAND_NAME } from '../../constants';
import Polling from '../Header/Polling';
import { TiSocialInstagram } from 'react-icons/ti';
import { FaTelegramPlane } from 'react-icons/fa';
import { SiTwitter } from 'react-icons/si';

const FooterWrapper = styled.div`
  padding: 0.3rem;
  font-size: 0.9em;
  color: ${({ theme }) => theme.text2};

  // add padding when internal links are in the bottom position
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 3.6rem;
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Copyright = styled.p`
  margin: 1rem 0;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
`;

const SocialLink = styled.a`
  font-size: 1.6em;
  color: ${({ theme }) => theme.primary3};
  transition: 0.2s;

  & + & {
    margin-left: 17%;
  }

  :hover {
    opacity: 0.7;
  }
`;

export default function Footer() {
  const year = 2021;
  const copyright = `© ${year} ${BRAND_NAME}`;

  return (
    <FooterWrapper>
      <Content>
        <Copyright>{copyright}</Copyright>

        <SocialLinksWrapper>
          <SocialLink href="https://twitter.com/min_i_ng" target="_blank">
            <SiTwitter title="Twitter" />
          </SocialLink>

          <SocialLink href="https://instagram.com/min.i.ng" target="_blank">
            <TiSocialInstagram title="Instagram" />
          </SocialLink>

          <SocialLink href="https://t.me/min_i_ng" target="_blank">
            <FaTelegramPlane title="Telegram" />
          </SocialLink>
        </SocialLinksWrapper>
      </Content>

      <Polling />
    </FooterWrapper>
  );
}
