const PANCAKE_EXTENDED =
  "https://tokens.pancakeswap.finance/pancakeswap-extended.json";
const PANCAKE_TOP100 =
  "https://tokens.pancakeswap.finance/pancakeswap-top-100.json";

const MINING_TOKENS =
  "https://my.miningtaxi.com/miningswap-tokens.json";

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  MINING_TOKENS,
  PANCAKE_EXTENDED,
  PANCAKE_TOP100,
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [MINING_TOKENS];
